const social_media_data = [
  {
    index: 1,
    icon_class: "fas fa fa-github",
    where_to_go_link: "https://github.com/monicagramle",
  },
  {
    index: 2,
    icon_class: "fas fa fa-instagram",
    where_to_go_link: "https://www.instagram.com/frontendconcepts",
  },
  {
    index: 3,
    icon_class: "fas fa fa-linkedin",
    where_to_go_link: "https://www.linkedin.com/in/monica-m-gramle-80a95411b/",
  },
  // {
  //   index: 4,
  //   icon_class: "fas fa fa-twitter",
  //   where_to_go_link: "https://twitter.com/ganraj_21",
  // },
];

export default social_media_data;
