import React, { useRef, useState } from 'react';
import './Contact.css';
import contact_me_img from '../Image/contact_me_img.png';
import Spinner from './components/Spinner';
import { BiLogInCircle } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import personal_info_data from '../pages/assets/AboutIn';

const Contact = (props) => {
  const url = 'https://formspree.io/f/mpzvopvg';
  const [pvalue, setPvalue] = useState(0);
  const [userMsg, setUserMsg] = useState({
    name: '',
    email: '',
    message: '',
  });

  const form = useRef();

  const toastOptions = {
    position: 'top-right',
    autoClose: 8000,
    pauseOnHover: true,
    theme: 'dark',
  };
  const handleInputs = (event) => {
    setUserMsg({ ...userMsg, [event.target.name]: event.target.value });
  };
  const handleValidation = () => {
    const { name, email, message } = userMsg;

    if (message.length < 5) {
      toast.error('Message is required', toastOptions);
      return false;
    } else if (name === '') {
      toast.error('Enter your name here.', toastOptions);
      return false;
    } else if (name.length < 2) {
      toast.error('Enter your full name', toastOptions);
      return false;
    } else if (email === '') {
      toast.error('Email is required', toastOptions);
      return false;
    }
    return true;
  };
  // const operator = async (e) => {
  //   e.preventDefault();
  //   const { name, email, message } = userMsg;
  //   const requestOptions = {
  //     name,
  //     email,
  //     message,
  //   };
  //   console.log(requestOptions);

  //   if (handleValidation()) {
  //     setPvalue(1);
  //     const res = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(requestOptions),
  //     });

  //     const data = await res.json();

  //     if (data) {
  //       setPvalue(0);
  //       emailjs.send(
  //         'service_mcjo9r8',
  //         'template_bp5qxel',
  //         form.current,
  //         'vjE1B1bCwi4pnyP7J'
  //       );

  //       toast.success(data.message, toastOptions);
  //       e.stopPropagation();
  //     }
  //     console.log(data);
  //     toast.error(data.error, toastOptions);
  //   }
  // };

  const operator = async (e) => {
    e.preventDefault();
    const { name, email, message } = userMsg;
    const requestOptions = {
      name,
      email,
      message,
    };

    // Validate the form fields
    if (!handleValidation()) {
      return;
    }

    setPvalue(1);

    try {
      // Send POST request
      const res = await fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestOptions),
      });

      if (res) {
        // Successful response
        setPvalue(0);
        toast.success('Sent', toastOptions);

        // Clear the form fields
        setUserMsg({
          name: '',
          email: '',
          message: '',
        });
      } else {
        // Handle fetch error
        throw new Error('Failed to send data');
      }
    } catch (error) {
      // Handle fetch and emailjs errors
      console.error('Error sending data:', error);
      setPvalue(0);
      toast.error('An error occurred while sending data', toastOptions);
    }
  };

  return (
    <section className="contact_page_section" id="contact_page">
      <div className="contact_section">
        <div className="contact_container">
          <div className="form_container_left">
          <div className="bot_contact_info">
          <h3>
          Contact <span> Me </span>
                </h3>
                <p>I am currently open to full-time, contract & part-time opportunities in Front-end Development</p>
                <span>Let's Discuss</span>
           {personal_info_data.map((data, index) => {
                return (
                  <div className="bot_cdiv" key={index}>
                    {data.section_two.map((e, i) => {
                      return (
                        <div className="contact_me_here" key={i}>
                          <i
                            className={e.icon_class}
                            style={{ color: e.ic_color }}
                          ></i>
                          <p>{e.data_value}</p>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              </div>
              </div>
            <div className="user_form">
              <ToastContainer />
              <form
                className="user_message_form"
                method="POST"
                onSubmit={operator}
              >
                <div className="name_val">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={userMsg.name}
                    onChange={handleInputs}
                    autoComplete="off"
                  />
                </div>
                <div className="username_val">
                  <label htmlFor="name">Email-id</label>
                  <input
                    type="email"
                    name="email"
                    value={userMsg.email}
                    onChange={handleInputs}
                    autoComplete="off"
                  />
                </div>
                <div className="user_msg">
                  <label htmlFor="msg">Message</label>
                  <textarea
                    type="text"
                    name="message"
                    id="user_text_area"
                    cols="30"
                    rows="6"
                    value={userMsg.message}
                    onChange={handleInputs}
                  ></textarea>
                </div>
                <div className="user_send_msg_button">
                  <button
                    className="submit_msg"
                    id="user_msg_btn"
                    disabled={pvalue !== 0}
                    style={{
                      background: pvalue !== 0 ? '#d2b3e5' : '',
                      border: pvalue !== 0 ? '#d2b3e5' : '',
                    }}
                  >
                    {pvalue ? (
                      <Spinner
                        id="your_spinner_d"
                        style={pvalue ? 'flex' : 'none'}
                      />
                    ) : (
                      <BiLogInCircle />
                    )}
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
         
        </div>
    </section>
  );
};

export default Contact;
