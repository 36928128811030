const personal_info_data = [
  {
    index: 1,
    section_one: [
      {
        index: 1,
        data_type: "Age",
        data_value: "29",
      },
      {
        index: 2,
        data_type: "Gender",
        data_value: "Female",
      },
      {
        index: 3,
        data_type: "Languages",
        data_value: "English Hindi Kannada Marathi",
      },
    ],
    section_two: [
      {
        index: 1,
        data_type: "Phone",
        data_value: "+44-7442267105",
        icon_class: "fa-solid fa-phone-flip",
        ic_color: "#fff",
      },
      {
        index: 2,
        data_type: "Email",
        data_value: "gramlemonica@gmail.com",
        icon_class: "fa-solid fa-envelope",
        ic_color: "#fff",
      },
      {
        index: 3,
        data_type: "Address",
        data_value: " London, UK - E26FD",
        icon_class: "fa-sharp fa-solid fa-location-dot",
        ic_color: "#fff",
      },
    ],
  },
];

export default personal_info_data;
