import React from "react";
import about_me_img from "../Image/about_me_img.jpg";
import personal_info_data from "./assets/AboutIn";
import "./About.css";
function About() {
  return (
    <section className="about_me" id="about_myself">
      <div className="container_01">
        <div className="my_img">
          <img id="img" src={about_me_img} alt="profile_image" />
        </div>
        <div className="my_info">
          <h2>
            About <span>Me</span>
          </h2>
          <p>
          👋 Hi there! I'm Monica, a seasoned Frontend Engineer with a passion for crafting delightful digital experiences. With over 5 years of experience in React and Angular development, I thrive on building robust features, resolving complex bugs, and optimizing performance to create exceptional user interfaces.

🚀 Throughout my career, I've had the opportunity to work on a variety of projects, from developing data reports and landing pages to integrating third-party services and optimizing site performance. My commitment to writing clean, maintainable code and implementing reusable components allows me to deliver high-quality solutions efficiently.
          </p>
          {/* <div className="personal_info">
            {personal_info_data.map((data, index) => {
              return (
                <>
                  <div className="abc" key={index}>
                    {data.section_one.map((e, index) => {
                      return (
                        <h4 key={index}>
                          <span>{e.data_type} :</span> {e.data_value}
                        </h4>
                      );
                    })}
                  </div>
                  <div className="xyz">
                    {data.section_two.map((e, index) => {
                      return (
                        <h4>
                          <span>{e.data_type} :</span> {e.data_value}
                        </h4>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default About;
