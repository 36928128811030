import profile_img from '../../Image/proj_imgs/profile_card.png';
import travel_img from '../../Image/proj_imgs/travel_web.png';
import blog_img from '../../Image/proj_imgs/blog.png';
import weatherlog_img from '../../Image/proj_imgs/weatherlog.png';
import shopping_img from '../../Image/proj_imgs/shopping_img.png';
import zing from '../../Image/proj_imgs/zing_img.png';
import coffeecoders from '../../Image/proj_imgs/coffeecoders.png'

const proj_data = [
  {
  id: 1,
  project_name: 'Coffee Coders/ Angular',
  proj_creation_date: 'Apr 2024',
  project_image: coffeecoders,
  proj_code_like: 'https://github.com/monicagramle/angular-website-example',
  proj_live_link: 'https://coffeecoders.co.uk',
}, 
{
    id: 2,
    project_name: 'Weather Application/Angular',
    proj_creation_date: 'Sept 2023',
    project_image: weatherlog_img,
    proj_code_like: 'https://github.com/monicagramle/weather-app',
    proj_live_link: 'https://weather-hpohakp0g-monicagramles-projects.vercel.app/',
  },
  {
    id: 3,
    project_name: 'Shopping Website/React',
    proj_creation_date: 'Feb 2022',
    project_image: shopping_img,
    proj_code_like: 'https://github.com/monicagramle/shopping-time',
    proj_live_link: 'https://shopping-time-pdey82nkd-monicagramles-projects.vercel.app/',
  },
  {
    id: 4,
    project_name: 'Zing-Digital Wallet/ React',
    proj_creation_date: 'May 2023',
    project_image: zing,
    proj_code_like: '#',
    proj_live_link: 'https://zing.me/',
  },
  {
    id: 5,
    project_name: 'Travel Website/Angular',
    proj_creation_date: 'June 2022',
    project_image: travel_img,
    proj_code_like: '#',
    proj_live_link: 'https://angular-shopping-website-caouls.stackblitz.io/packages',
  },
  {
    id: 6,
    project_name: 'Profile Card / HTML/CSS',
    proj_creation_date: 'feb 2021',
    project_image: profile_img,
    proj_code_like: 'https://github.com/monicagramle/Profile-Card-main',
    proj_live_link: 'https://profile-card-main-omega.vercel.app',
  },
  {
    id: 7,
    project_name: 'Marvel-Blog/Angular',
    proj_creation_date: 'March 2022',
    project_image: blog_img,
    proj_code_like: 'https://github.com/monicagramle/angular-blog',
    proj_live_link: 'https://angular-blog-mu-eight.vercel.app',
  },
];

export default proj_data;
